import React, { useEffect, useState } from 'react';
import Sketch from 'react-p5';

const P5Sketch = () => {
  const [data, setData] = useState({
    current_block: null,
    total_transactions: null,
    transactions: []
  });

  useEffect(() => {
    const fetchTransactions = () => {
      fetch('/api/transactions')
        .then((response) => response.json())
        .then((data) => {
          setData(data);
        })
        .catch((error) => console.error('Error fetching transactions:', error));
    };

    fetchTransactions();
    const intervalId = setInterval(fetchTransactions, 5000);
    return () => clearInterval(intervalId);
  }, []);

  const setup = (p, canvasParentRef) => {
    p.createCanvas(p.windowWidth, p.windowWidth * 0.75, p.WEBGL).parent(canvasParentRef);

    // Adjust perspective to reduce fish-eye effect
    p.perspective(p.PI / 3, p.width / p.height, 0.1, 10000);
  };

  const windowResized = (p) => {
    const newWidth = p.windowWidth;
    const newHeight = newWidth * 0.75; // Keep aspect ratio 4:3
    p.resizeCanvas(newWidth, newHeight);
  };

  const draw = (p) => {
    p.background(255);

    // Reduce the perspective effect (by making the fov smaller)
    p.perspective(p.PI / 3, p.width / p.height, 0.1, 10000);

    // Scale the orbit radius based on canvas width for responsiveness
    const scaleFactor = p.width / 1000;

    // Calculate the maximum allowable orbit radius based on canvas size
    const maxOrbitRadius = Math.min(p.width, p.height) / 2 - 50 * scaleFactor;
    
    const baseSphereSize = 30 * scaleFactor;  // Adjust sphere size based on screen size

    p.rotateY(p.frameCount * 0.02);
    const tiltAngle = p.radians(15);
    p.rotateX(tiltAngle);

    const { transactions: txns } = data;
    if (!Array.isArray(txns) || txns.length === 0) {
      p.push();
      p.fill(0);
      p.textAlign(p.CENTER, p.CENTER);
      p.textSize(24);
      p.text('Loading transactions...', 0, 0);
      p.pop();
      return;
    }

    // Adjust the sphere size based on the number of transactions
    const maxSphereSize = baseSphereSize;
    const minSphereSize = 10 * scaleFactor;  // Define a minimum size for the spheres
    const sphereSize = p.map(txns.length, 1, 100, maxSphereSize, minSphereSize);

    // Dynamically adjust orbit radius based on the number of transactions
    const orbitRadius = p.map(txns.length, 1, 100, maxOrbitRadius / 2, maxOrbitRadius);

    txns.forEach((tx, i) => {
      const angle = (p.TWO_PI / txns.length) * i;
      p.push();
      p.rotateY(angle);
      p.translate(orbitRadius, 0, 0);

      if (tx.type === 'pay') {
        p.fill(130, 120, 180);
      } else if (tx.type === 'appl') {
        p.fill(180, 120, 130);
      } else {
        p.fill(120, 180, 130);
      }

      p.sphere(sphereSize);

      if (Array.isArray(tx.inner_transactions)) {
        const innerTxns = tx.inner_transactions;
        const numInnerTxns = innerTxns.length;
        const spheresPerLayer = 30;
        const layerSpacing = 10;
        const innerOrbitRadius = 50;
        const numLayers = Math.ceil(numInnerTxns / spheresPerLayer);
        const innerSphereSize = Math.max(2, 8 - numLayers) * scaleFactor;

        for (let layer = 0; layer < numLayers; layer++) {
          const startIdx = layer * spheresPerLayer;
          const endIdx = Math.min(startIdx + spheresPerLayer, numInnerTxns);
          const layerTxns = innerTxns.slice(startIdx, endIdx);
          const layerRadius = innerOrbitRadius + layer * layerSpacing;

          layerTxns.forEach((innerTx, j) => {
            p.push();
            const innerAngle = (p.TWO_PI / layerTxns.length) * j;
            p.rotateY(innerAngle);
            p.translate(layerRadius, 0, 0);
            p.fill(0);
            p.sphere(innerSphereSize);
            p.pop();
          });
        }
      }
      p.pop();
    });
  };

  const legendItems = [
    { color: 'rgb(130, 120, 180)', label: 'Payment Transactions' },
    { color: 'rgb(180, 120, 130)', label: 'Application Transactions' },
    { color: 'rgb(120, 180, 130)', label: 'Asset Transfer Transactions' },
    { color: 'rgb(0, 0, 0)', label: 'Inner Transactions' }
  ];

  return (
    <div style={{ position: 'relative', display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
      <div
        style={{
          position: 'absolute',
          top: 20,
          left: 20,
          backgroundColor: 'rgba(255, 255, 255, 0.8)',
          padding: '10px',
          borderRadius: '5px'
        }}
      >
        <h2>Current Block: {data.current_block || 'Loading...'}</h2>
        <h3>Total Transactions: {data.total_transactions || 'Loading...'}</h3>
        <div>
          {legendItems.map((item, index) => (
            <div key={index} style={{ display: 'flex', alignItems: 'center', marginTop: '5px' }}>
              <div
                style={{
                  width: '15px',
                  height: '15px',
                  backgroundColor: item.color,
                  marginRight: '10px'
                }}
              ></div>
              <span>{item.label}</span>
            </div>
          ))}
        </div>
      </div>

      <Sketch setup={setup} draw={draw} windowResized={windowResized} />
    </div>
  );
};

export default P5Sketch;
